import { navigate } from "gatsby"
import React from "react"
import MenuButton from "../Menu/MenuButton"
import "./Skip.css"
import SkipBar from "./SkipBar"

const Skip = ({ barProgress }) => {
  return (
    <div className="menu-buttons fadeinfast" style={{ zIndex: 12 }}>
      <MenuButton
        buttonContent={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span
              style={{
                fontSize: "var(--font-xsmall)",
                fontFamily: "Roboto, Helvetica Neue, Arial, sans-serif",
              }}
            >
              Skip
            </span>
            <SkipBar barProgress={barProgress} />
          </div>
        }
        onClick={() => {
          navigate("/visiting-room")
        }}
        styling={{
          flexDirection: "column",
        }}
        tooltipActive
        tooltipStyling={{
          background: "none",
          boxShadow: "none",
          marginRight: 0,
        }}
        tooltipContent={null}
      />
    </div>
  )
}

export default Skip
