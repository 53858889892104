import React from "react"
import Home from "../components/Home/Home"
import useClickDetector from "../utils/useClickDetector"
import HeaderSEO from "../components/Header/HeaderSEO"

const IndexPage = () => {
  useClickDetector()

  return (
    <>
      <Home />
    </>
  )
}

export const Head = () => <HeaderSEO />
export default IndexPage
