import React, { useEffect, useState } from "react"
import Header from "../Header/Header"
import Loading from "../Loading"
import "./Home.css"
import HomeVideo from "./HomeVideo"
import Skip from "./Skip"
import "./Skip.css"

const Home = ({ images }) => {
  const [isLoading, setLoading] = useState(true)
  const [barProgress, setBarProgress] = useState(0)
  const [hideWords, setHideWords] = useState(false)

  useEffect(() => {
    if (barProgress > 0.01) {
      setLoading(false)
    }
    if (barProgress > 0.5) {
      setHideWords(true)
    }
  }, [barProgress])

  return (
    <>
      <div className="home">
        <div className="landing" style={{ background: "black" }}>
          <HomeVideo images={images} setBarProgress={setBarProgress} />
        </div>
        <Header title={"The Visiting Room Project"} classes="center" hideMenu />
        <div className={`landing fadeinfast`}>
          <div
            className={`subtitle ${hideWords ? "fadeout" : "fadeinfast"}`}
            style={{
              background: "rgba(0,0,0,0.2)",
            }}
          >
            <p>
              Nearly 5,000 people are sentenced to die in Louisiana prisons
              without any possibility of parole.
            </p>
            <p>
              This is the largest collection of first-person testimonials ever
              gathered from people serving this sentence.
            </p>
          </div>
        </div>
        {isLoading ? (
          <div className="loader">
            <Loading size="small" />
          </div>
        ) : (
          <Skip barProgress={barProgress} />
        )}
      </div>
    </>
  )
}

export default Home
