import React, { useState } from "react"
import "./MenuButton.css"

const MenuButton = ({
  theme,
  onClick,
  onKeyUp,
  onMouseDown,
  onMouseUp,
  onTouchStart,
  onTouchEnd,
  buttonContent,
  styling,
  classes = "",
  tooltipContent,
  tooltipActive,
  tooltipStyling,
}) => {
  const [isTooltip, setTooltip] = useState(false)
  const [isHolding, setHolding] = useState(false)

  return (
    <div
      className={`menu-button-tooltip ${isHolding ? "holding" : ""} ${classes}`}
      style={
        styling || {
          flexDirection: "column",
        }
      }
    >
      <button
        className={`menu-button ${theme === "light" ? "menu-light" : ""}`}
        tabIndex="0"
        onClick={onClick}
        onKeyUp={onKeyUp}
        onMouseDown={(e) => {
          if (onMouseDown) {
            setHolding(true)
            setTooltip(false)
            onMouseDown(e)
          }
        }}
        onMouseUp={(e) => {
          if (onMouseUp) {
            setHolding(false)
            onMouseUp(e)
          }
        }}
        onTouchStart={(e) => {
          if (onTouchStart) {
            setHolding(true)
            setTooltip(false)
            onTouchStart(e)
          }
        }}
        onTouchEnd={(e) => {
          if (onTouchEnd) {
            setHolding(false)
            onTouchEnd(e)
          }
        }}
        onMouseEnter={() => {
          setTooltip(true)
        }}
        onMouseLeave={(e) => {
          setTooltip(false)
          if (onMouseUp) onMouseUp(e)
        }}
      >
        {buttonContent}
      </button>
      <div
        className={`menu-tooltip menu-tooltip-desktop ${
          tooltipActive || isTooltip ? "active" : ""
        }`}
        style={tooltipStyling}
      >
        {tooltipContent}
      </div>
      <div
        className={`menu-tooltip menu-tooltip-mobile ${
          tooltipActive ? "active" : ""
        }`}
        style={tooltipStyling}
      >
        {tooltipContent}
      </div>
    </div>
  )
}

export default MenuButton
